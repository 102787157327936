import { Card, CardHeader, CardMedia, Checkbox, Chip, Divider, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DownloadIcon from '@mui/icons-material/Download';
import InfoIcon from '@mui/icons-material/Info';
import config from "src/config";
import AddEditFileModal from "src/pages/AddEditFile.modal";
import CircleIcon from '@mui/icons-material/Circle';
import VerifiedIcon from '@mui/icons-material/Verified';
import TaskIcon from '@mui/icons-material/Task';
import ShareIcon from '@mui/icons-material/Share';
import ArchiveIcon from '@mui/icons-material/Archive';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import BusinessIcon from '@mui/icons-material/Business';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import FolderIcon from '@mui/icons-material/Folder';
import StarsIcon from '@mui/icons-material/Stars';

const WordImg = require('./../../assets/fileIcons/word.png');
const ExcelImg = require('./../../assets/fileIcons/excel.png');
const PowerpointImg = require('./../../assets/fileIcons/powerpoint.png');
const PdfImg = require('./../../assets/fileIcons/pdf.png');
const ImageImg = require('./../../assets/fileIcons/image.png');
const AudioImg = require('./../../assets/fileIcons/audio.png');
const VideoImg = require('./../../assets/fileIcons/video.png');
// const ArchiveImg = require('./../../assets/fileIcons/archive.jpeg');
const NoteImg = require('./../../assets/fileIcons/note.png');
const CadImg = require('./../../assets/fileIcons/cad.png');
const DesignImg = require('./../../assets/fileIcons/design.png');
const TextImg = require('./../../assets/fileIcons/text.png');
const CodeImg = require('./../../assets/fileIcons/code.png');
const FileImg = require('./../../assets/fileIcons/file.png');

import OnedriveLogo from 'src/assets/storageIcons/onedrive.png';
import DropboxLogo from 'src/assets/storageIcons/dropbox.png';
import GoogleDriveLogo from 'src/assets/storageIcons/googledrive.png';
import { green } from "@mui/material/colors";
import { fData } from "src/utils/formatNumber";
import { getDownloadLink, viewFile } from "src/services/storage.service";

const fileIcon = {
	'word': WordImg,
	'excel': ExcelImg,
	'powerpoint': PowerpointImg,
	'pdf': PdfImg,
	'image': ImageImg,
	'audio': AudioImg,
	'video': VideoImg,
	// 'archive': ArchiveImg,
	'note': NoteImg,
	'cad': CadImg,
	'design': DesignImg,
	'text': TextImg,
	'code': CodeImg,
	'file': FileImg
}

const flags = [
	{ value: 'attachment', label: 'Attachment', color: 'purple', icon: <AttachFileIcon style={{fontSize: '18px', color: 'purple'}} /> },
	{ value: 'important', label: 'important', color: 'red', icon: <StarsIcon style={{fontSize: '18px', color: 'red'}} /> },
	{ value: 'in_house', label: 'In house', color: 'blue', icon: <BusinessIcon style={{fontSize: '18px', color: 'blue'}} /> },
	{ value: 'temp', label: 'Temp', color: 'black', icon: <RunningWithErrorsIcon style={{fontSize: '18px', color: 'black'}} /> },
	{ value: 'wip', label: 'WIP', color: 'orange', icon: <WorkHistoryIcon style={{fontSize: '18px', color: 'orange'}} /> },
	{ value: 'complete', label: 'Complete', color: 'green', icon: <DoneIcon style={{fontSize: '18px', color: 'green'}} /> },
	{ value: 'final', label: 'Final', color: 'red', icon: <DoneAllIcon style={{fontSize: '18px', color: 'red'}} /> },
	{ value: 'archive', label: 'Archive', color: 'grey', icon: <ArchiveIcon style={{fontSize: '18px', color: 'grey'}} /> },
	{ value: 'duplicate', label: 'Duplicate', color: 'brown', icon: <ContentCopyIcon style={{fontSize: '18px', color: 'brown'}} /> },
]

class FileCard extends React.Component {


	constructor(props) {
		super(props);
		this.state = {
			fileEditModal: false
		}
	}

	goToFileLocation = async (file) => {
		if (file.service == config.FILE_SERVICES.DROPBOX) {
			const response = await viewFile(file.connected_service, file._id);
			if (response.error) {
				alert('Error viewing file location. Please try again later. Check connection status with Dropbox');
				return;
			}
			let url = response.file.preview_link;
			url = url.split('/').slice(0, -1).join('/');
			window.open(url, '_blank');
		} else if (file.service == config.FILE_SERVICES.ONEDRIVE) {
			let url = file.external_details.webUrl;
			url = url.split('/').slice(0, -1).join('/');
			window.open(url, '_blank');
		} else if (file.service == config.FILE_SERVICES.GOOGLEDRIVE) {
			let url = "https://drive.google.com/drive/folders/" + file.external_details.parents[0];
			window.open(url, '_blank');
		}
	}

	viewFile = async (file) => {
		if (file.service == config.FILE_SERVICES.DROPBOX) {
			const response = await viewFile(file.connected_service, file._id);
			const url = response.file.preview_link;
    		window.open(url, '_blank');
		} else if (file.service == config.FILE_SERVICES.ONEDRIVE) {
			window.open(file.external_details.webUrl, '_blank');
		} else if (file.service == config.FILE_SERVICES.GOOGLEDRIVE) {
			const response = await viewFile(file.connected_service, file._id);
			const url = response.file;
    		window.open(url, '_blank');
		}
	}

	getFileInfo = async (file) => {
		// this.props.navigate(`/files/${file._id}`);
		this.setState({ fileEditModal: true });

	}

	downloadFile = async (file) => {
		let response = await getDownloadLink(file.connected_service, file._id);
		if (response.error) {
			console.log('Error getting download link', response.error);
			alert('Error downloading file ' + file.name + '. Please try again later. Check connection status');
			return;
		}
		window.open(response.download_link, '_blank');
	}

	addToWallet = async (file) => {
		console.log('Add to wallet', file);
	}

	menu = () => {
		return (
			<Menu
				id="simple-menu"
				anchorEl={this.state.anchorEl}
				open={Boolean(this.state.anchorEl)}
				onClose={() => this.setState({anchorEl: null})}
				anchorReference="anchorPosition"
				anchorPosition={{ top: this.state.clickY, left: this.state.clickX }}
			>
				<MenuItem onClick={() => {
					this.getFileInfo(this.props.file)
					this.setState({anchorEl: null})
				}}>
					<ListItemIcon>
						<InfoIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText>
						Info
					</ListItemText>
				</MenuItem>
				{(this.props?.file?.connected_service ? (
					<>
					<MenuItem onClick={() => {
						this.goToFileLocation(this.props.file)
						this.setState({anchorEl: null})
					}}>
						<ListItemIcon>
							<FolderIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText>
							Go to location
						</ListItemText>
					</MenuItem>
					<MenuItem onClick={() => {
						this.viewFile(this.props.file)
						this.setState({anchorEl: null})
					}}>
						<ListItemIcon>
							<RemoveRedEyeIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText>
							View
						</ListItemText>
					</MenuItem>
					<MenuItem onClick={() => {
						this.downloadFile(this.props.file)
						this.setState({anchorEl: null})
					}}>
						<ListItemIcon>
							<DownloadIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText>
							Download
						</ListItemText>
					</MenuItem>
					</>
				) : null)}
				<Divider />
				<MenuItem onClick={() => {
					this.setState({anchorEl: null})
					this.props?.onSelect(this.props.file);
				}}>
					<ListItemIcon>
						<TaskIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText>
						{(this.props?.selected) ? 'Unselect' : 'Select'}
					</ListItemText>
				</MenuItem>
				{/* <Divider />
				<MenuItem onClick={() => {
					this.setState({anchorEl: null})
				}}>
					<ListItemIcon>
						<WalletIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText>
						Add to My Wallet
					</ListItemText>
				</MenuItem> */}
			</Menu>
		)
	}

	// card header title
	cardHeaderTitle = (file) => {
		let show_selection = this.props?.showSelection;
		return (
			<>
				{(show_selection) ? (
					<Checkbox
						checked={this.props?.selected}
						onChange={() => this.props?.onSelect(file)}
					/>
				) : null}
				{file.name}
			</>
		)
	}

	getFileIcon = (file) => {
		if (file.icon && file.icon != '' && file.icon in fileIcon) {
			return fileIcon[file.icon];
		} else if (file.extension && file.extension in fileIcon) {
			return fileIcon[file.extension];
		} else {
			return fileIcon['file'];
		}
	}

	render() {

		let file = this.props.file;

		// first n labels
		let first_n_labels = (file.labels && file.labels.length > 5) ? file.labels.slice(0, 5) : (file.labels ? file.labels : []);

		return (
			<>
			<Card variant="outlined"
				sx={{
					display: 'flex', flexDirection: 'column', height: '100%', padding: '8px', paddingBottom: '0px', paddingTop: '5px',
					opacity: ((file.inactive_age && file.inactive_age > 365) ? (0.25) : ((file.inactive_age && file.inactive_age > 90) ? (0.5) : 1))
				}}
				onContextMenu={(e) => {
					e.preventDefault();
					this.setState({
						anchorEl: e.currentTarget,
						clickX: e.clientX,
						clickY: e.clientY
					});
				}}
				onDoubleClick={() => this.viewFile(file)}
			>
				{this.menu()}
				<Grid flex={1} flexDirection={'row'} justifyContent={'space-between'} display={'flex'}>
					<CardHeader
						style={{padding: '0px', wordBreak: 'break-all'}}
						title={this.cardHeaderTitle(file)}
						titleTypographyProps={{variant: 'subtitle2', color: 'gray'}}
					/>
					<CardMedia
						sx={{height: 20, width: 20}}
						component="img"
						image={this.getFileIcon(file)}
						alt={file.name}
					/>
				</Grid>
				<Grid sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
					<Typography variant="caption" color="textSecondary" component="div">
						{moment(file?.real_modified_at).format("MMMM Do YYYY, h:mm:ss a")}
					</Typography>
					<Typography variant="caption" color="textSecondary" component="div" sx={{display: 'flex', alignItems: 'center'}}>
						{(file.flags && file.flags.length > 0) ? file.flags.map((flag, idx) => {

							let flag_data = flags.find(f => f.value == flag);
							if (flag_data.icon) {
								return (
									flag_data.icon
								)
							}

							let color = flags.find(f => f.value == flag)?.color;
							if (!color) color = 'yellow';
							return (
								<CircleIcon key={idx} style={{fontSize: '14px', color: color, alignItems: 'center', borderColor: color}} />
							)
						}) : null}
					</Typography>
				</Grid>
				<Grid sx={{display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between', padding: '0px'}}>
					<Grid sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height: '100%'}}>
						<Grid>
							{(first_n_labels && first_n_labels.length > 0) ? first_n_labels.map((label, idx) => {
								return (
									<Chip size="small" variant='outlined' sx={{ borderRadius: 1, mr: 1, fontWeight: ((idx == 0 || idx == 1) ? 700 : undefined), borderColor: ((idx == 0 || idx == 1) ? 'black' : undefined), fontSize: '15px' }} label={label} key={idx} />
								)
							}) : (
								<Chip size="small" variant='outlined' sx={{ borderRadius: 1 }} label={'No Labels'} disabled />
							)}
						</Grid>
					</Grid>
					<Grid>
						<Stack direction={'row'} sx={{justifyContent: 'space-between'}}>
							<div style={{maxWidth: '50%', display: 'flex', alignItems: 'center'}}>
								<IconButton aria-label="view"
									disabled
									size="small"
								>
									{(file.service == config.FILE_SERVICES.DROPBOX) ? <img src={DropboxLogo} style={{width: '20px', height: 'auto'}} /> : null}
									{(file.service == config.FILE_SERVICES.ONEDRIVE) ? <img src={OnedriveLogo} style={{width: '20px', height: 'auto'}} /> : null}
									{(file.service == config.FILE_SERVICES.GOOGLEDRIVE) ? <img src={GoogleDriveLogo} style={{width: '20px', height: 'auto'}} /> : null}
								</IconButton>
								{(file.organized) ? (
									<VerifiedIcon fontSize="small" sx={{color: green[300]}} />
								) : null}
								{(file.shared) ? (
									<ShareIcon fontSize="small" sx={{color: 'gray', marginLeft: 0.5}} />
								) : null}
								{/* {(file.containers && file.containers.length > 0) ? (
									<Chip
										size="small"
										variant='outlined'
										sx={{
											borderRadius: 1,
											maxWidth: '100%', // Set your desired max width here
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											whiteSpace: 'nowrap'
										}}
										label={file.containers.join(' / ')}
										disabled
									/>
								) : null} */}
							</div>
							<div>

								<Chip size="small" variant='outlined' sx={{ borderRadius: 1 }} label={fData(file.size)} disabled />
								<IconButton aria-label="view"
									onClick={() => this.getFileInfo(file)}
									size="small"
								>
									<InfoIcon fontSize="small" />
								</IconButton>
								{(file.connected_service) ? (
									<>
									<IconButton aria-label="view"
										onClick={() => this.viewFile(file)}
										size="small"
									>
										<RemoveRedEyeIcon fontSize="small" />
									</IconButton>
									<IconButton aria-label="download"
										onClick={() => this.downloadFile(file)}
										size="small"
									>
										<DownloadIcon fontSize="small" />
									</IconButton>
									</>
								) : null}
							</div>
						</Stack>
					</Grid>
				</Grid>
			</Card>
			{(this.state.fileEditModal) ? (
				<AddEditFileModal
					open={this.state.fileEditModal}
					file={file}
					onClose={() => this.setState({fileEditModal: false})}
					onSave={() => {
						this.setState({fileEditModal: false})
						this.props.onReload();
					}}
				/>
			) : null}
			</>
		)
	}
}

const modalStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 2,
};


// Wrap and export
export default function (props) {
    const navigate = useNavigate();
    const params = useParams();

    return <FileCard {...props} navigate={navigate} params={params} />;
}
