import axios from './axios.service';
import config from './../config';
import { getQueryStringFromObject, getRequestHeaders } from './common.service';

const getStorages = async (query=null) => {
    try {
      let queryString = '';
      if (query) {
          queryString = '?' + getQueryStringFromObject(query);
      }
      let response;
      try {
          let headers = getRequestHeaders();
          response = await axios.get( config.SERVER_BASE_URL + '/storages' + queryString, { headers: headers });
      } catch (error) {
          return error.response.data;
      }
      return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getStorageDetails = async (storageId) => {
    try {
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/storages/' + storageId, {
                headers: getRequestHeaders()
            });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getAvailableStorageServices = async () => {
    try {
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/services/available', {
                headers: getRequestHeaders()
            });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const updateStorageEmail = async (id, email) => {
    try {
        let response;
        try {
            response = await axios.post( config.SERVER_BASE_URL + '/storages/' + id + '/update_email', { email }, {
                headers: getRequestHeaders()
            });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const syncStorage = async (storageId) => {
    try {
        let response;
        try {
            response = await axios.post( config.SERVER_BASE_URL + '/storages/' + storageId + '/sync', {}, {
                headers: getRequestHeaders()
            });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const updateStorageDetails = async (storageId, payload) => {
    try {
        let response;
        try {
            response = await axios.patch( config.SERVER_BASE_URL + '/storages/' + storageId, payload, {
                headers: getRequestHeaders()
            });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getStorageStatus = async (storageId) => {
    try {
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/storages/' + storageId + '/status', {
                headers: getRequestHeaders()
            });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getDownloadLink = async (storageId, fileId) => {
    try {
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/storages/' + storageId + '/download_link/' + fileId, {
                headers: getRequestHeaders()
            });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const viewFile = async (storageId, fileId) => {
    try {
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/storages/' + storageId + '/view/' + fileId, {
                headers: getRequestHeaders()
            });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const downloadFile = async (storageId, fileId) => {
    try {
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/storages/' + storageId + '/download/' + fileId, {
                headers: getRequestHeaders(),
                responseType: 'blob'
            });
            return response;
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const connectStorage = async (type, payload) => {
    try {
        let response;
        try {
            response = await axios.post( config.SERVER_BASE_URL + '/storages/' + type + '/connect', payload, {
                headers: getRequestHeaders()
            });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

export {
    getStorages,
    getStorageDetails,
    getAvailableStorageServices,
    updateStorageEmail,
    syncStorage,
    updateStorageDetails,
    getStorageStatus,
    getDownloadLink,
    viewFile,
    downloadFile,
    connectStorage
}
