import React from 'react';
import { Button, Grid, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SyncIcon from '@mui/icons-material/Sync';
import { ReactWebStore } from 'react-webstore';
import DropboxLogo from 'src/assets/storageIcons/dropbox.png';
import DropboxLogoDisconnected from 'src/assets/storageIcons/dropbox_disconnected.png';
import { connectStorage, getStorageStatus, syncStorage, updateStorageEmail } from 'src/services/storage.service';
import config from 'src/config';

// ----------------------------------------------------------------------

class OnedriveStatusCheckAndConnect extends React.Component {
  
  constructor(props) {
    super(props);

    this.rws = new ReactWebStore();
    this.state = {
      connected: false,
      syncing: false,
      unsyncing: false,
      name: '',
      email: '',
      storageId: props?.storageId || ''
    }

  }

  async componentDidMount() {
    // get the variables from the url which starts from #
    let hash = window.location.hash;
    // remove the # from the hash
    hash = hash.substring(1);

    const params = new URLSearchParams(hash);
    const accessToken = params.get('access_token');

    if (accessToken) {

      const tokenType = params.get('token_type');
      const expiresIn = params.get('expires_in');
      const scope = params.get('scope');
      const uid = params.get('uid');
      const accountId = params.get('account_id');

      let payload = {
        access_token: accessToken,
        token_type: tokenType,
        expires_in: expiresIn,
        scope: scope,
        uid: uid,
        account_id: accountId
      };

      // send to server
      let connectDropboxRes = await connectStorage(config.FILE_SERVICES.DROPBOX, payload);
      if (connectDropboxRes.error) {
        console.log('connectDropboxRes.error', connectDropboxRes.error);
        alert('Error connecting Dropbox');
        window.history.pushState({}, document.title, window.location.pathname);  
      } else {
        this.setState({
          connected: true
        });
        alert('Dropbox connected successfully');
        window.history.pushState({}, document.title, window.location.pathname);
      }

    } else {
      if (this.state.storageId) {
        // Check dropbox status
        let dropboxStatusRes = await getStorageStatus(this.state.storageId);
        if (dropboxStatusRes.error) {
          this.setState({
            connected: false
          });
          return;
        }

        updateStorageEmail(this.state.storageId, dropboxStatusRes.email);

        this.setState({
          connected: (dropboxStatusRes.status == 'active') ? true : false,
          name: dropboxStatusRes.name || '',
          email: dropboxStatusRes.email || ''
        });
      }
    }



  }


  connectDropbox = () => {
    const appKey = process.env.REACT_APP_DROPBOX_APP_KEY;
    const redirectUri = this.props.callbackUrl ? encodeURIComponent(this.props.callbackUrl) : encodeURIComponent(process.env.REACT_APP_DROPBOX_CALLBACK_URL);
    const url = `https://www.dropbox.com/oauth2/authorize?response_type=token&client_id=${appKey}&redirect_uri=${redirectUri}`;
    window.location.href = url;
  }

  syncFiles = async () => {

    this.setState({
      syncing: true
    });

    let syncDropboxRes = await syncStorage(this.state.storageId);
    if (syncDropboxRes.error) {
      this.setState({
        syncing: false
      });
      console.log('syncDropboxRes.error', syncDropboxRes.error);
      this.rws.store('sync_files', 'failed');
      alert('Error syncing Dropbox');
    } else {
      this.setState({
        syncing: false
      });
      this.rws.store('sync_files', 'success');
      if (syncDropboxRes.message_code == 'syncing_in_background') {
        alert('Dropbox files syncing in background. It may take some time. We will send a mail when its done');
      } else {
        alert(syncDropboxRes.message ? syncDropboxRes.message : 'Dropbox synced successfully');
      }
    }
  }

  // unsyncFiles = async () => {

  //   let confirm = await Swal.fire({
  //     title: 'Are you sure?',
  //     text: 'Do you want to unsync Dropbox? All the labels and containers will be removed and the files will be deleted from dMonk server. The files will remain in your Dropbox account.',
  //     input: 'text',
  //     inputPlaceholder: 'Type "delete dropbox data" to confirm',
  //     inputValidator: (value) => {
  //       if (value !== 'delete dropbox data') {
  //         return 'You need to type "delete dropbox data" to confirm';
  //       }
  //     },
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonText: 'Yes, Unsync it!',
  //     confirmButtonColor: 'red',
  //     cancelButtonText: 'No, keep it'
  //   });

  //   if (!confirm?.isConfirmed) {
  //     return;
  //   }

  //   if (confirm.value !== 'delete dropbox data') {
  //     Swal.fire('Error!', 'You need to type "delete dropbox data" to confirm', 'error');
  //     return;
  //   }

  //   this.setState({
  //     unsyncing: true
  //   });

  //   let unsyncDropboxRes = await unsyncDropbox();
  //   if (unsyncDropboxRes.error) {
  //     this.setState({
  //       unsyncing: false
  //     });
  //     console.log('unsyncDropboxRes.error', unsyncDropboxRes.error);
  //     Swal.fire('Error!', 'Error unsyncing Dropbox', 'error');
  //   } else {
  //     this.setState({
  //       unsyncing: false
  //     });
  //     console.log('unsyncDropboxRes.success', unsyncDropboxRes.success);
  //     Swal.fire('Success!', 'Dropbox unsynced successfully', 'success');
  //   }
  // }

  
  render() {
    return (
      <Grid>
          {(this.state.connected) ? (
            <>
              {(this.props.minimal) ? (
                null
              ) : (
                <>
                  {/* Get the account name */}
                  {(this.state.name || this.state.email) ? (
                        <>
                          <p>Connected as <b>{this.state.name || ''}</b> with email <b>{this.state.email || ''}</b></p>
                        </>
                  ) : null}

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.connectDropbox}
                    disabled
                    sx={{ mr: 2 }}
                  >
                    Connected
                  </Button>
                </>
              )}

              {(this.props.minimal) ? (
                <IconButton aria-label="connected"
                  onClick={this.syncFiles}
                >
                  {(this.state.syncing) ? (
                    <SyncIcon style={{color: 'gray', animation: 'spin 2s linear infinite reverse'}} />
                  ) : (
                    <img src={DropboxLogo} style={{width: '25px', height: 'auto'}} />
                  )}
                </IconButton>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={this.connectDropbox}
                    sx={{ mr: 2 }}
                  >
                    Re-Connect
                  </Button>

                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={this.syncFiles}
                    disabled={this.state.syncing}
                    sx={{ mr: 2 }}
                  >
                    {this.state.syncing ? 'Syncing' : 'Sync'}
                  </Button>
                </>
              )}

            </>

          ) : (
            <>
              {(this.props.minimal) ? (
                <IconButton aria-label="connected"
                  onClick={this.connectDropbox}
                >
                  <img src={DropboxLogoDisconnected} style={{width: '25px', height: 'auto'}} />
                </IconButton>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.connectDropbox}
                  sx={{ mr: 2 }}
                >
                  Connect Dropbox
                </Button>
              )}
            </>
          )}

          {/* {(this.props.hideUnsyncButton) ? null : (
            <Button
              variant="outlined"
              color="error"
              onClick={this.unsyncFiles}
              disabled={this.state.unsyncing}
            >
              {this.state.unsyncing ? 'Un-Syncing' : 'Un-Sync'}
            </Button>
          )} */}
      </Grid>
    );
  }
}

export default function (props) {
    const navigate = useNavigate();

    return <OnedriveStatusCheckAndConnect {...props} navigate={navigate} />;
}
