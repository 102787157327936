const WordImg = require('./../../assets/fileIcons/word.png');
const ExcelImg = require('./../../assets/fileIcons/excel.png');
const PowerpointImg = require('./../../assets/fileIcons/powerpoint.png');
const PdfImg = require('./../../assets/fileIcons/pdf.png');
const ImageImg = require('./../../assets/fileIcons/image.png');
const AudioImg = require('./../../assets/fileIcons/audio.png');
const VideoImg = require('./../../assets/fileIcons/video.png');
const ArchiveImg = require('./../../assets/fileIcons/archive.png');
const CadImg = require('./../../assets/fileIcons/cad.png');
const DesignImg = require('./../../assets/fileIcons/design.png');
const NoteImg = require('./../../assets/fileIcons/note.png');
const TextImg = require('./../../assets/fileIcons/text.png');
const CodeImg = require('./../../assets/fileIcons/code.png');
const FileImg = require('./../../assets/fileIcons/file.png');


// ----------------------------------------------------------------------

const fileSubCategoryIcon = {
	'word': WordImg,
	'excel': ExcelImg,
	'powerpoint': PowerpointImg,
	'pdf': PdfImg,
	'image': ImageImg,
	'audio': AudioImg,
	'video': VideoImg,
	'archive': ArchiveImg,
    'cad': CadImg,
    'design': DesignImg,
    'note': NoteImg,
    'text': TextImg,
    'code': CodeImg,
	'file': FileImg
}

export { fileSubCategoryIcon };
