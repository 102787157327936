import axios from './axios.service';
import config from './../config';
import { getQueryStringFromObject, getRequestHeaders } from './common.service';

async function getDuplicateFiles(query=null) {
  try {
    let queryString = '';
    if (query) {
        queryString = '?' + getQueryStringFromObject(query);
    }
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.get( config.SERVER_BASE_URL + '/declutter/duplicates' + queryString, { headers: headers });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
  } catch (error) {
      return { error: error.message };
  }
}

async function getDuplicateStats(query=null) {
  try {
    let queryString = '';
    if (query) {
        queryString = '?' + getQueryStringFromObject(query);
    }
    let response;
    try {
        let headers = getRequestHeaders();
        response = await axios.get( config.SERVER_BASE_URL + '/declutter/duplicates/stats' + queryString, { headers: headers });
    } catch (error) {
        return error.response.data;
    }
    return response.data;
  } catch (error) {
      return { error: error.message };
  }
}

export {
  getDuplicateFiles,
  getDuplicateStats
}
