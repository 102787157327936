import React from 'react';
import { Button, Grid, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated } from '@azure/msal-react';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from 'src/config/msal.config';
import SyncIcon from '@mui/icons-material/Sync';
import { ReactWebStore } from 'react-webstore';


// import image
import OnedriveLogo from 'src/assets/storageIcons/onedrive.png';
import OnedriveLogoDisconnected from 'src/assets/storageIcons/onedrive_disconnected.png';
import ArrayInput from 'src/components/array-input';
import { connectStorage, getStorageDetails, syncStorage, updateStorageDetails, updateStorageEmail } from 'src/services/storage.service';
import config from 'src/config';

// ----------------------------------------------------------------------

class OnedriveStatusCheckAndConnect extends React.Component {
  
  constructor(props) {
    super(props);

    this.rws = new ReactWebStore();
    this.state = {
      connected: false,
      syncing: false,
      unsyncing: false,
      sharepoint_site_ids: [],
      storageId: props?.storageId || '',
      instance: props.instance,
      accounts: props.accounts,
      connected_account: null
    }

  }

  async componentDidMount() {

    if (this.state.storageId) {
      let storage_details = await getStorageDetails(this.state.storageId);
      if (storage_details.error) {
        console.log('storage_details.error', storage_details.error);
        return;
      }
      let storage_username = storage_details?.data?.account?.username;
      let connected_account = this.props.accounts.find(account => account.username == storage_username);
      this.setState({
        connected_account: connected_account,
        sharepoint_site_ids: storage_details?.sharepoint_site_ids || []
      });
    }

    setTimeout(() => {
      this.props?.accounts?.forEach(async (account) => {
        await this.connectAndUpdateOnedriveDetails(account);
      });
    }, 5000);

  }

  componentDidUpdate(prevProps) {
    if (this.props.accounts !== prevProps.accounts) {
      let storage_username = this.state?.connected_account?.username;
      let connected_account = this.props.accounts.find(account => account.username == storage_username);
      this.setState({
        connected_account: connected_account
      });
    }
  }



  connectOnedrive = async () => {
      this.props.instance
        .loginRedirect({...loginRequest, prompt: "select_account"})
        .catch(e => {
          console.error(e);
        });
  }

  connectAndUpdateOnedriveDetails = async (con_acc = null) => {

    try {
      const instance = this.props.instance;
      const accounts = this.props.accounts;

      let connected_account = con_acc ? con_acc : this.state.connected_account;

      if (this.state.connected_account) {

        return new Promise((resolve, reject) => {
          instance
            .acquireTokenSilent({
                ...loginRequest,
                account: connected_account,
            })
            .then(async (response) => {
              // send to server
              let connectOnedriveRes = await connectStorage(config.FILE_SERVICES.ONEDRIVE, response);
              if (connectOnedriveRes.error) {
                console.log('connectOnedriveRes.error', connectOnedriveRes.error);
                alert('Error connecting Onedrive');
                window.history.pushState({}, document.title, window.location.pathname);  
                resolve();
              } else {
                this.setState({
                  connected: true
                });
                window.history.pushState({}, document.title, window.location.pathname);
                resolve();
              }
            })
            .catch((error) => {
                console.error('error', error);
                alert('Error connecting Onedrive');
                window.history.pushState({}, document.title, window.location.pathname);  
                resolve();
            });
        });
      }
    } catch (error) {
      console.error('error', error);
      alert('Error connecting Onedrive');
    }

    
  }

  syncFiles = async () => {

    this.setState({
      syncing: true
    });

    // await this.connectAndUpdateOnedriveDetails();

    let syncOnedriveRes = await syncStorage(this.state.storageId);
    if (syncOnedriveRes.error) {
      this.setState({
        syncing: false
      });
      console.log('syncOnedriveRes.error', syncOnedriveRes.error);
      this.rws.store('sync_files', 'failed');
      alert('Error syncing Onedrive');
    } else {
      this.setState({
        syncing: false
      });
      this.rws.store('sync_files', 'success');
      if (syncOnedriveRes.message_code == 'syncing_in_background') {
        alert('Onedrive files syncing in background. It may take some time. We will send a mail when its done');
      } else {
        alert(syncOnedriveRes.message ? syncOnedriveRes.message : 'Onedrive synced successfully');
      }
    }
  }

  // unsyncFiles = async () => {

  //   let confirm = await Swal.fire({
  //     title: 'Are you sure?',
  //     text: 'Do you want to unsync Onedrive? All the labels and containers will be removed and the files will be deleted from dMonk server. The files will remain in your Onedrive account.',
  //     input: 'text',
  //     inputPlaceholder: 'Type "delete onedrive data" to confirm',
  //     inputValidator: (value) => {
  //       if (value !== 'delete onedrive data') {
  //         return 'You need to type "delete onedrive data" to confirm';
  //       }
  //     },
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonText: 'Yes, Unsync it!',
  //     confirmButtonColor: 'red',
  //     cancelButtonText: 'No, keep it'
  //   });

  //   if (!confirm?.isConfirmed) {
  //     return;
  //   }

  //   if (confirm.value !== 'delete onedrive data') {
  //     Swal.fire('Error!', 'You need to type "delete onedrive data" to confirm', 'error');
  //     return;
  //   }

  //   this.setState({
  //     unsyncing: true
  //   });

  //   let unsyncOnedriveRes = await unsyncOnedrive();
  //   if (unsyncOnedriveRes.error) {
  //     this.setState({
  //       unsyncing: false
  //     });
  //     console.log('unsyncOnedriveRes.error', unsyncOnedriveRes.error);
  //     Swal.fire('Error!', 'Error unsyncing Onedrive', 'error');
  //   } else {
  //     this.setState({
  //       unsyncing: false
  //     });
  //     console.log('unsyncOnedriveRes.success', unsyncOnedriveRes.success);
  //     Swal.fire('Unsynced!', 'Onedrive has been unsynced.', 'success');
  //   }
  // }

  updateOnedriveDetails = async () => {

    let payload = {
      sharepoint_site_ids: this.state.sharepoint_site_ids
    };

    let updateOnedriveDetailsRes = await updateStorageDetails(this.state.storageId, payload);
    if (updateOnedriveDetailsRes.error) {
      console.log('updateOnedriveDetailsRes.error', updateOnedriveDetailsRes.error);
      alert('Error updating');
    } else {
      console.log('updateOnedriveDetailsRes.success', updateOnedriveDetailsRes.success);
      alert('Update successful');
    }
  }

  
  render() {

    if (this.state.storageId && this.state?.connected_account?.username) {
      updateStorageEmail(this.state.storageId, this.state?.connected_account?.username);
    }

    if (!this.props?.storageId) {
      return (
        <>
          {(this.props.minimal) ? (
              <IconButton aria-label="connected"
                  onClick={this.connectOnedrive}
              >
                  <img src={OnedriveLogoDisconnected} style={{width: '25px', height: 'auto'}} />
              </IconButton>
          ) : (
              <Button
                  variant="contained"
                  color="primary"
                  onClick={this.connectOnedrive}
                  sx={{ mr: 2 }}
              >
                  Connect Onedrive
              </Button>
          )}

          {/* {(this.props.hideUnsyncButton) ? null : (
              <Button
                  variant="outlined"
                  color="error"
                  onClick={this.unsyncFiles}
                  disabled={this.state.unsyncing}
              >
                  {this.state.unsyncing ? 'Un-Syncing' : 'Un-Sync'}
              </Button>
          )} */}
        </>
      )
    }

    return (
      <Grid>

            <AuthenticatedTemplate>
                {(this.props.minimal) ? (
                    <IconButton aria-label="connected"
                        onClick={this.syncFiles}
                    >
                        {(this.state.syncing) ? (
                          <SyncIcon style={{color: 'gray', animation: 'spin 2s linear infinite reverse'}} />
                        ) : (
                          <img src={OnedriveLogo} style={{width: '25px', height: 'auto'}} />
                        )}
                    </IconButton>
                ) : (
                    <>

                        {/* Get the account name */}
                        {this.state.connected_account && (this.state.connected_account.name || this.state.connected_account.username) ? (
                            <>
                              <p>Connected as <b>{this.state.connected_account?.name || ''}</b> with username <b>{this.state.connected_account?.username || ''}</b></p>
                            </>
                        ) : null}

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.connectOnedrive}
                            disabled
                            sx={{ mr: 2 }}
                        >
                            Connected
                        </Button>

                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={this.connectOnedrive}
                            sx={{ mr: 2 }}
                        >
                            Re-Connect
                        </Button>
                    </>
                )}

                {(this.props.minimal) ? (
                    null
                ) : (
                    <Button
                    variant="contained"
                    color="secondary"
                    onClick={this.syncFiles}
                    disabled={this.state.syncing}
                    sx={{ mr: 2 }}
                    >
                    {this.state.syncing ? 'Syncing' : 'Sync'}
                    </Button>
                )}

                {/* {(this.props.minimal) ? (
                    null
                ) : (
                    <Button
                    variant="outlined"
                    color="error"
                    onClick={this.unsyncFiles}
                    disabled={this.state.unsyncing}
                    >
                    {this.state.unsyncing ? 'Un-Syncing' : 'Un-Sync'}
                    </Button>
                )} */}





                {(this.props.minimal) ? (
                    null
                ) : (
                  <>
                    <ArrayInput
                      title="Sharepoint Site IDs"
                      buttonText="Add Site ID"
                      placeholderText="Enter Site ID"
                      allowOrdering={false}
                      value={this.state.sharepoint_site_ids}
                      onChange={(sharepoint_site_ids) => {
                        this.setState({
                          sharepoint_site_ids: sharepoint_site_ids
                        });
                      }}
                      style={{marginTop: '20px'}}
                    />

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.updateOnedriveDetails}
                      disabled={this.state.syncing}
                      sx={{ mt: 2 }}
                    >
                      Update Onedrive Details
                    </Button>
                  </>
                )}



            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>

                {(this.props.minimal) ? (
                    <IconButton aria-label="connected"
                        onClick={this.connectOnedrive}
                    >
                        <img src={OnedriveLogoDisconnected} style={{width: '25px', height: 'auto'}} />
                    </IconButton>
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.connectOnedrive}
                        sx={{ mr: 2 }}
                    >
                        Connect Onedrive
                    </Button>
                )}

                {/* {(this.props.hideUnsyncButton) ? null : (
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={this.unsyncFiles}
                        disabled={this.state.unsyncing}
                    >
                        {this.state.unsyncing ? 'Un-Syncing' : 'Un-Sync'}
                    </Button>
                )} */}

            </UnauthenticatedTemplate>
         
      </Grid>
    );
  }
}

export default function (props) {
    const navigate = useNavigate();

    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    return <OnedriveStatusCheckAndConnect {...props} navigate={navigate} isAuthenticated={isAuthenticated} instance={instance} accounts={accounts} />;
}
