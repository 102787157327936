import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardContent, CardHeader, Chip, Grid, Stack } from '@mui/material';
import { PieChart } from '@mui/x-charts';
import AppSimpleBarChart from 'src/sections/@dashboard/app/AppSimpleBarChart';
import { getDuplicateFiles, getDuplicateStats } from 'src/services/declutter.service';
import FileCard from 'src/components/file-card';
import { fData } from 'src/utils/formatNumber';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import { deleteMultipleFiles, updateFlagsToMultipleFiles } from 'src/services/file.service';
import showLoading from 'src/common/loading';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import moment from 'moment';
import ArchiveIcon from '@mui/icons-material/Archive';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { AppWidgetSummary } from 'src/sections/@dashboard/app';
import { fileSubCategoryIcon } from 'src/components/icons';

class DuplicateFiles extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            by_hash: [],
            selected: {
                files: [],
                total_size: 0
            },
            stats: [],
            total_duplicates: 0,
            total_size: 0,
            selected_stat: null
        }
    }

    async setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }

    async componentDidMount() {
        this.reloadDuplicateStats();
        await this.reloadDuplicateFiles(false);
    }

    async resetState() {
        await this.setStateAsync({
            by_hash: [],
            selected: {
                files: [],
                total_size: 0
            }
        });
    }

    async reloadDuplicateFiles(resetState=true) {
        showLoading(true);

        let query = {};
        if (this.state.selected_stat) {
            query.sub_category = this.state.selected_stat.sub_category;
        }

        

        if (resetState) {
            await this.resetState();
        }

        let duplicate_files = await getDuplicateFiles(query);
        
        if (duplicate_files.error) {
            showLoading(false);
            console.error(duplicate_files.error);
            return;
        }
        duplicate_files = duplicate_files.duplicate_files;

        let by_hash_array = duplicate_files.by_hash || [];

        by_hash_array = by_hash_array.map((hash) => {
            // sort hash.files
            hash.files = hash.files.sort((a, b) => moment(b.real_modified_at) - moment(a.real_modified_at));
            return hash;
        });
        
        this.setState({
            by_hash: by_hash_array
        });

        showLoading(false);
    }

    async reloadDuplicateStats() {

        let duplicate_stats = await getDuplicateStats();
        if (duplicate_stats.error) {
            console.error(duplicate_stats.error);
            return;
        }
        duplicate_stats = duplicate_stats.duplicate_stats;

        let stats = duplicate_stats.stats || {};
        let total_duplicates = duplicate_stats.total_duplicates || 0;
        let total_size = duplicate_stats.total_size || 0;

        this.setState({
            stats,
            total_duplicates,
            total_size
        });
    }

    async confirmDeleteSelected() {
        let c = await Swal.fire({
            title: 'Delete Selected Files?',
            text: `Are you sure you want to delete ${this.state.selected.files.length} files? You can't undo this action.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            confirmButtonColor: 'red'
        });

        return c.isConfirmed;

    }

    async handleSelectAutomatically() {

        let selected = this.state.selected.files;
        if (selected.length) {
            let c = await Swal.fire({
                title: 'Are you sure?',
                text: `This action will remove the selections you made and make new selections. Do you want to continue?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            });
            if (!c.isConfirmed) {
                return;
            }
        }

        await this.setStateAsync({
            selected: {
                files: [],
                total_size: 0
            }
        });

        showLoading(true);

        let files = [];
        let total_size = 0;

        for (let hash of this.state.by_hash) {
            let dulicated_files = hash.files;

            // check if there are any organized files
            let organized_files = dulicated_files.filter(f => f.organized);
            if (organized_files.length) {
                // select the files which are not organized
                let unorganized_files = dulicated_files.filter(f => !f.organized);
                files = files.concat(unorganized_files.map(f => f._id));
                total_size += unorganized_files.reduce((acc, f) => acc + f.size, 0);

                // select all organized files except the latest one
                let files_to_select = organized_files.slice(1);
                files = files.concat(files_to_select.map(f => f._id));
                total_size += files_to_select.reduce((acc, f) => acc + f.size, 0);
            } else {
                // select all files except the first one
                let files_to_select = dulicated_files.slice(1);
                files = files.concat(files_to_select.map(f => f._id));
                total_size += files_to_select.reduce((acc, f) => acc + f.size, 0);
            }

        }

        await this.setStateAsync({
            selected: {
                files,
                total_size
            }
        });

        showLoading(false);

    }

    async handleDeleteSelected() {
        let c = await this.confirmDeleteSelected();
        if (!c) {
            return;
        }

        showLoading(true);
        

        let payload = {
            file_ids: this.state.selected.files
        };

        let response = await deleteMultipleFiles(payload);
        if (response.error) {
            showLoading(false);
            console.error(response.error);
            alert('Error deleting files');
            return;
        }

        await this.setStateAsync({
            selected: {
                files: [],
                total_size: 0
            }
        });

        this.reloadDuplicateStats();
        await this.reloadDuplicateFiles();

        await showLoading(false);

        let error_mesasge = '';
        if (response?.failed_responses?.length) {
            error_mesasge += '<br /><br />'
            error_mesasge += response.failed_responses.map(f => f.error).join('<br />');
        }

        Swal.fire({
            title: 'Files Deleted',
            icon: 'success',
            html: `Total : ${response.total}<br />Success : ${response.success}<br />Failed : ${response.failed}${error_mesasge}`,
            showConfirmButton: false
        });

    }

    async handleFlagAsArchived() {
        let c = await Swal.fire({
            title: 'Flag Selected Files as Archive?',
            text: `Are you sure you want to flag ${this.state.selected.files.length} files as Archive?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        });
        if (!c.isConfirmed) {
            return;
        }

        showLoading(true);

        let response = await updateFlagsToMultipleFiles({
            flags: ['archive'],
            operation: "append",
            file_ids: this.state.selected.files
        });
        if (response.error) {
            showLoading(false);
            console.error(response.error);
            alert('Error flagging files as Archive');
            return;
        }

        this.reloadDuplicateFiles(false);

        showLoading(false);
    }

    async handleFlagAsDuplicate() {
        let c = await Swal.fire({
            title: 'Flag Selected Files as Duplicate?',
            text: `Are you sure you want to flag ${this.state.selected.files.length} files as Duplicate?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        });
        if (!c.isConfirmed) {
            return;
        }

        showLoading(true);

        let response = await updateFlagsToMultipleFiles({
            flags: ['duplicate'],
            operation: "append",
            file_ids: this.state.selected.files
        });
        if (response.error) {
            showLoading(false);
            console.error(response.error);
            alert('Error flagging files as Duplicate');
            return;
        }

        this.reloadDuplicateFiles(false);

        showLoading(false);
    }

    render() {

        return (
            <>

                {(this.state.total_duplicates > 0 || this.state.total_size > 0) ? (
                    <Grid container spacing={2} mb={2}>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <AppWidgetSummary
                                title="Total"
                                color="error"
                                total={this.state.total_size}
                                count={this.state.total_duplicates}
                                icon={<ContentCopyIcon style={{width: '45px', height: 'auto'}} />}
                            />
                        </Grid>
                        {(this.state.stats.map((stat, index) => {
                            return (
                                <Grid item>
                                    <Card variant='outlined'
                                        style={{
                                            cursor: 'pointer',
                                            backgroundColor: this.state.selected_stat?.sub_category === stat.sub_category ? '#f0f0f0' : 'white'
                                        }}
                                        onClick={async () => {
                                            await this.setStateAsync({
                                                selected_stat: (this.state.selected_stat?.sub_category === stat.sub_category) ? null : stat
                                            });
                                            this.componentDidMount();
                                        }}
                                    >
                                        <CardContent>
                                            <Stack direction="row" spacing={2} style={{textAlign: 'center', alignItems: 'center'}}>
                                                <img src={fileSubCategoryIcon[stat.sub_category] ? fileSubCategoryIcon[stat.sub_category] : fileSubCategoryIcon['file']} style={{width: 'auto', height: '45px'}} />
                                                <Stack direction="column" spacing={1} style={{textAlign: 'center'}}>
                                                    <h2>{fData(stat.size)}</h2>
                                                    <h5>{stat.count + ' Files'}</h5>
                                                </Stack>
                                            </Stack>
                                        </CardContent>
                                    </Card>

                                    {/* <AppWidgetSummary
                                        title={stat._id}
                                        color="info"
                                        total={stat.size}
                                        count={stat.count}
                                        // icon={<ContentCopyIcon style={{width: '45px', height: 'auto'}} />}
                                        icon={<img src={fileSubCategoryIcon[stat.sub_category] ? fileSubCategoryIcon[stat.sub_category] : fileSubCategoryIcon['file']} style={{width: '45px', height: 'auto'}} />}
                                    /> */}
                                </Grid>
                            );
                        }))}
                    </Grid>
                ) : null}


                {(this.state.by_hash.length > 0) ? (
                    <>
                        {(this.state.total_files > 0 || this.state?.selected?.files?.length) ? (
                            <Stack direction="row" spacing={2} justifyContent={"space-between"} sx={{mb: 2}}>
                                {(this.state?.selected?.files?.length > 0) ? (
                                    <Chip
                                        label={`Selected Files: ${this.state.selected.files.length} | Size: ${fData(this.state.selected.total_size)}`}
                                        onDelete={async () => {
                                            await this.setStateAsync({
                                                selected: {
                                                    files: [],
                                                    total_size: 0
                                                }
                                            });
                                        }}
                                    />

                                ) : <Grid></Grid>}
                                {(this.state.total_files > 0) ? (
                                    <Chip
                                        label={`Total Files: ${this.state.total_files} | Total Size: ${fData(this.state.total_size)}`}
                                    />
                                ) : <Grid></Grid>}
                            </Stack>
                        ) : null}
                    </>
                ) : (
                    <>
                        {/* center aligned card */}
                        <Card variant='outlined' sx={{textAlign: 'center'}}>
                            <CardHeader title="No Duplicates Found" />
                        </Card>
                    </>
                )}

                {/* Bulk Actions */}
                {(this.state?.selected?.files?.length) ? (
                    <Stack direction="row" spacing={2} sx={{mb: 2}}>
                        <Chip
                            label="Delete Selected"
                            color='error'
                            icon={<DeleteIcon />}
                            variant='outlined'
                            onClick={async () => {
                                await this.handleDeleteSelected();
                            }}
                        />
                        <Chip
                            label="Flag selected files as Archive"
                            color='info'
                            icon={<ArchiveIcon />}
                            variant='outlined'
                            onClick={async () => {
                                await this.handleFlagAsArchived();
                            }}
                        />
                        <Chip
                            label="Flag selected files as Duplicate"
                            color='info'
                            icon={<ContentCopyIcon />}
                            variant='outlined'
                            onClick={async () => {
                                await this.handleFlagAsDuplicate();
                            }}
                        />
                    </Stack>
                ) : null}

                {(this.state.by_hash.length > 0) ? (
                    <Stack direction="row" spacing={2} sx={{mb: 2}}>
                        <Chip
                            label="Automatically select files to declutter"
                            icon={<FactCheckIcon />}
                            variant='outlined'
                            onClick={async () => {
                                await this.handleSelectAutomatically();
                            }}
                        />
                    </Stack>
                ) : null}

                {(this.state.by_hash.map((hash) => {
                    return (

                        <Card variant='outlined' key={hash.hash} sx={{mb: 2}}>
                            <CardContent>
                            <Grid container spacing={1}>
                                {hash?.files?.map((file) => {
                                    return (
                                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={file._id}>
                                            <FileCard
                                                key={file._id}
                                                file={file}
                                                showSelection={true}
                                                selected={this.state.selected.files.includes(file._id)}
                                                onSelect={async (file) => {
                                                    let selected = this.state.selected;
                                                    if (selected.files.includes(file._id)) {
                                                        selected.files = selected.files.filter(f => f !== file._id);
                                                        selected.total_size -= file.size;
                                                    } else {
                                                        selected.files.push(file._id);
                                                        selected.total_size += file.size;
                                                    }
                                                    await this.setStateAsync({
                                                        selected,
                                                        show_selection: true
                                                    });
                                                }}
                                                onReload={async () => {
                                                    await this.reloadDuplicateFiles();
                                                }}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                            </CardContent>
                        </Card>
                    );
                }))}
            </>
        );
    }
}

// Wrap and export
export default function (props) {
    const navigate = useNavigate();
    const params = useParams();

    return <DuplicateFiles {...props} navigate={navigate} params={params} />;
}
