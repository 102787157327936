import React, { useState } from 'react';
import { Button, Grid, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SyncIcon from '@mui/icons-material/Sync';
import { ReactWebStore } from 'react-webstore';
import { useGoogleLogin } from '@react-oauth/google';

import GoogleDriveLogo from 'src/assets/storageIcons/googledrive.png';
import GoogleDriveLogoDisconnected from 'src/assets/storageIcons/googledrive_disconnected.png';
import config from 'src/config';
import { connectStorage, getStorageStatus, syncStorage, updateStorageEmail } from 'src/services/storage.service';

// ----------------------------------------------------------------------

class GoogleDriveStatusCheckAndConnect extends React.Component {

	constructor(props) {
		super(props);

		this.rws = new ReactWebStore();
		this.state = {
			connected: false,
			syncing: false,
			unsyncing: false,
			name: '',
			email: '',
      		storageId: props?.storageId || ''
		}

	}

	async componentDidMount() {




		// get the variables from the url which starts from #
		let query = window.location.search;
		
		let code = new URLSearchParams(query).get('code');
		let scope = new URLSearchParams(query).get('scope');
		let error = new URLSearchParams(query).get('error');
		let errorDescription = new URLSearchParams(query).get('error_description');	
		
		if (code) {
			let payload = {
				code: code,
				scope: scope,
				redirect_uri: this.props.callbackUrl ? this.props.callbackUrl : process.env.REACT_APP_GOOGLEDRIVE_CALLBACK_URL
			};

			let connectGoogleDriveRes = await connectStorage(config.FILE_SERVICES.GOOGLEDRIVE, payload);
			if (connectGoogleDriveRes.error) {
				console.log('connectGoogleDriveRes.error', connectGoogleDriveRes.error);
				// alert('Error connecting Google Drive');
			} else {
				this.setState({
					connected: true
				});
				window.history.pushState({}, document.title, window.location.pathname);
				window.location.reload();
				// alert('Google Drive connected successfully');
			}
		} else if (error) {
			console.log('Error connecting Google Drive', error, errorDescription);
			// alert('Error connecting Google Drive');
		} else {
			if (this.state.storageId) {
				// Check googledrive status
				let googleDriveStatusRes = await getStorageStatus(this.state.storageId);
				if (googleDriveStatusRes.error) {
					this.setState({
						connected: false
					});
					return;
				}

				if (googleDriveStatusRes?.email) {
					updateStorageEmail(this.state.storageId, googleDriveStatusRes.email);
				}

				this.setState({
					connected: (googleDriveStatusRes.status == 'active') ? true : false,
					name: googleDriveStatusRes.name || '',
					email: googleDriveStatusRes.email || ''
				});
			}
		}
		
	}

	connectGoogleDrive = () => {
		const clientId = process.env.REACT_APP_GOOGLEDRIVE_CLIENT_ID;
		const redirectUri = this.props.callbackUrl ? encodeURIComponent(this.props.callbackUrl) : encodeURIComponent(process.env.REACT_APP_GOOGLEDRIVE_CALLBACK_URL);
		const scope = encodeURI('email profile https://www.googleapis.com/auth/drive openid https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email');
		const url = `https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=${scope}&response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&prompt=select_account`;
		window.location.href = url;
	}

	async componentDidUpdate(prevProps) {
		if (this.props.isConnected !== prevProps.isConnected) {
			this.setState({
				connected: this.props.isConnected
			});
		}
	}



	// connectGoogleDrive = async (payload) => {
	// 	let connectGoogleDriveRes = await connectGoogleDrive(payload);
	// 	// console.log('connectGoogleDriveRes', connectGoogleDriveRes)
	// 	if (connectGoogleDriveRes.error) {
	// 		console.log('connectGoogleDriveRes.error', connectGoogleDriveRes.error);
	// 		alert('Error connecting Google Drive');
	// 		window.history.pushState({}, document.title, window.location.pathname);
	// 		resolve();
	// 	} else {
	// 		this.setState({
	// 			connected: true
	// 		});
	// 		window.history.pushState({}, document.title, window.location.pathname);
	// 		resolve();
	// 	}
	// }

	syncFiles = async () => {

		this.setState({
			syncing: true
		});

		let syncGoogleDriveRes = await syncStorage(this.state.storageId);
		if (syncGoogleDriveRes.error) {
			this.setState({
				syncing: false
			});
			console.log('syncGoogleDriveRes.error', syncGoogleDriveRes.error);
			this.rws.store('sync_files', 'failed');
			alert('Error syncing Google Drive');
		} else {
			this.setState({
				syncing: false
			});
			this.rws.store('sync_files', 'success');
			if (syncGoogleDriveRes.message_code == 'syncing_in_background') {
				alert('Google Drive files syncing in background. It may take some time. We will send a mail when its is done');
			  } else {
				alert(syncGoogleDriveRes.message ? syncGoogleDriveRes.message : 'Google Drive files synced successfully');
			  }
		}
	}

	// unsyncFiles = async () => {

	// 	let confirm = await Swal.fire({
	// 		title: 'Are you sure?',
	// 		text: 'Do you want to unsync Google Drive? All the labels and containers will be removed and the files will be deleted from dMonk server. The files will remain in your Google Drive account.',
	// 		input: 'text',
	// 		inputPlaceholder: 'Type "delete googledrive data" to confirm',
	// 		inputValidator: (value) => {
	// 			if (value !== 'delete googledrive data') {
	// 				return 'You need to type "delete googledrive data" to confirm';
	// 			}
	// 		},
	// 		icon: 'warning',
	// 		showCancelButton: true,
	// 		confirmButtonText: 'Yes, Unsync it!',
	// 		confirmButtonColor: 'red',
	// 		cancelButtonText: 'No, keep it'
	// 	});

	// 	if (!confirm?.isConfirmed) {
	// 		return;
	// 	}

	// 	if (confirm.value !== 'delete googledrive data') {
	// 		Swal.fire('Error!', 'You need to type "delete googledrive data" to confirm', 'error');
	// 		return;
	// 	}

	// 	this.setState({
	// 		unsyncing: true
	// 	});

	// 	let unsyncGoogleDriveRes = await unsyncGoogleDrive();
	// 	if (unsyncGoogleDriveRes.error) {
	// 		this.setState({
	// 			unsyncing: false
	// 		});
	// 		console.log('unsyncGoogleDriveRes.error', unsyncGoogleDriveRes.error);
	// 		Swal.fire('Error!', 'Error unsyncing Google Drive', 'error');
	// 	} else {
	// 		this.setState({
	// 			unsyncing: false
	// 		});
	// 		console.log('unsyncGoogleDriveRes.success', unsyncGoogleDriveRes.success);
	// 		Swal.fire('Unsynced!', 'Google Drive has been unsynced.', 'success');
	// 	}
	// }


	googleLogin = async () => {
		const { useGLogin } = this.props;
		useGLogin();
	}


	render() {
		return (
			//     {/* <GoogleLogin
			//         onSuccess={async (credentialResponse) => {
			//             console.log(credentialResponse);
			//             await connectGoogleDrive(credentialResponse);
			//         }}
			//         onError={() => {
			//             console.log('Login Failed');
			//         }}
			//     /> */}

			<Grid>
				{(this.state.connected) ? (
					<>
						{(this.props.minimal) ? (
							null
						) : (
							<>
								{/* Get the account name */}
								{(this.state.name || this.state.email) ? (
									<>
										<p>Connected as <b>{this.state.name || ''}</b> with email <b>{this.state.email || ''}</b></p>
									</>
								) : null}

								<Button
									variant="contained"
									color="primary"
									// onClick={this.googleLogin}
									disabled
									sx={{ mr: 2 }}
								>
									Connected
								</Button>
							</>
						)}

						{(this.props.minimal) ? (
							<IconButton aria-label="connected"
								onClick={this.syncFiles}
							>
								{(this.state.syncing) ? (
									<SyncIcon style={{ color: 'gray', animation: 'spin 2s linear infinite reverse' }} />
								) : (
									// <AddToDriveIcon sx={{ color: 'green' }} />
									<img src={GoogleDriveLogo} style={{width: '25px', height: 'auto'}} />
								)}
							</IconButton>
						) : (
							<>
								<Button
									variant="outlined"
									color="primary"
									// onClick={this.googleLogin}
									onClick={this.connectGoogleDrive}
									sx={{ mr: 2 }}
								>
									Re-Connect
								</Button>

								<Button
									variant="contained"
									color="secondary"
									onClick={this.syncFiles}
									disabled={this.state.syncing}
									sx={{ mr: 2 }}
								>
									{this.state.syncing ? 'Syncing' : 'Sync'}
								</Button>
							</>
						)}

					</>

				) : (
					<>
						{(this.props.minimal) ? (
							<IconButton aria-label="connected"
								// onClick={this.googleLogin}
								onClick={this.connectGoogleDrive}
							>
								{/* <AddToDriveIcon sx={{ color: 'red' }} /> */}
								<img src={GoogleDriveLogoDisconnected} style={{width: '25px', height: 'auto'}} />
							</IconButton>
						) : (
							<Button
								variant="contained"
								color="primary"
								onClick={this.connectGoogleDrive}
								sx={{ mr: 2 }}
							>
								Connect Google Drive
							</Button>
						)}
					</>
				)}

				{/* {(this.props.hideUnsyncButton) ? null : (
					<Button
						variant="outlined"
						color="error"
						onClick={this.unsyncFiles}
						disabled={this.state.unsyncing}
					>
						{this.state.unsyncing ? 'Un-Syncing' : 'Un-Sync'}
					</Button>
				)} */}
			</Grid>




		);
	}
}

export default function (props) {
	const navigate = useNavigate();
	const [isConnected, setIsConnected] = useState(false);

	const useGLogin = useGoogleLogin({
		// flow: 'implicit',
		// auth_url: 'https://accounts.google.com/o/oauth2/v2/auth?access_type=offline',
		flow: 'auth-code',
		clientId: process.env.REACT_APP_GOOGLEDRIVE_CLIENT_ID,
		// scope: 'https://www.googleapis.com/auth/drive.file',
		scope: 'https://www.googleapis.com/auth/drive',
		redirect_uri: process.env.REACT_APP_GOOGLEDRIVE_CALLBACK_URL,
		access_type: 'offline',
		onSuccess: async (credentialResponse) => {

			console.log("---------- Credentials Response", credentialResponse)

			if (credentialResponse?.access_token || credentialResponse?.code) {
				await connectStorage(config.FILE_SERVICES.GOOGLEDRIVE, credentialResponse);
				setIsConnected(true);
			} else {
				// alert('Error connecting Google Drive');
				setIsConnected(false);
			}
		}
	});

	return <GoogleDriveStatusCheckAndConnect {...props} navigate={navigate} useGLogin={useGLogin} isConnected={isConnected} />;
}
